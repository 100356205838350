const variables= {

   urlApi: "https://pqrsf-dev.mrbono-app.pw/api",
   LSCONSTNAME :{
      CIUDAD: 'idCiudad',
      LINEANEGOCIO: 'IdlineaDeNegocio',
      ISWITHSURVEY: 'WithSurvey',
      CALIFICACION: 'calificacion',
      PQRID: 'PqrId',
      PREGUNTAF: 'preguntaF'
   }
}



module.exports= variables;