import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { Component } from 'react'
import Logo from '../../assets/Logo_01.png'

export default class ModalDatosDeContacto extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    // this.state({

    // })
  }


  handleClose = () => {
    const { onClose, selectedValue } = this.props;

    onClose(selectedValue);
  };
  render() {
    const { open } = this.props;
    return (
      <Dialog onClose={this.handleClose} open={open}>
        <DialogTitle className="item-center" >   {this.props.title || ""} </DialogTitle>
        <small className="item-center">
          <img src={Logo} alt="" width="130"></img>
        </small>
        <br />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <center>
              <h4>!Datos correctamente almacenados!</h4>
            </center>
            <center>
              <p>Si tuviste algún inconveniente y deseas que
              te contactemos, puedes contarnos brevemente
              lo sucedido y dejarnos tus datos:
             </p>
            </center>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.props.funcionCancel} color="primary">
            NO
          </Button>
          <Button onClick={this.props.funtionOK} color="primary">
            Si, dejar comentario
          </Button>
        </DialogActions>


      </Dialog>
    );

  }
}
