import React, { Fragment, Suspense, Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import '../App.css'
import InputBase from '@material-ui/core/InputBase';
import imgDigital from '../../assets/Digitalgexas3.png';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import variable from '../variables' 
// import { CircularProgress } from '@material-ui/core';
//import  loading  from "../../assets/loading.gif";
import  loadingPuntos  from "../../assets/loadingPuntos.svg";


const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        width: '13em',
        height: 20,
        borderRadius: '1em',
        position: 'relative',
        backgroundColor: '#FFb81c',
        alignItems: "center",
        alignContent: "center",
        //border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        margin: '10px 0px 10px 0px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: '1em',
            backgroundColor: '#FFb81c',
            borderColor: '#CAB453',
            //color: "#CAB453",
        },
    },
}))(InputBase);




class Identificacion extends Component {
    state = {
        ciudades: [],
        centroOperaciones: [],
        ciudad: "",
        centroOperacion: "",
        setAge: "",
        caragandoInical: false,
        
    }




    constructor(props) {
        super(props);
        this.siguiente = this.siguiente.bind(this);

    }
    siguiente(lineaNegocio) {
        const { history } = this.props;
        localStorage.setItem(variable.LSCONSTNAME.LINEANEGOCIO, lineaNegocio.id )
        localStorage.setItem(variable.LSCONSTNAME.ISWITHSURVEY, lineaNegocio.isWithSurvey )
        history.push("/datos/");
        
    }

    selecitemCiudad(id) {
        // alert("Este es el ID" + id)
        localStorage.setItem(variable.LSCONSTNAME.CIUDAD, id)
        //---------------------------------- Get Ciudades-------------------------//
        axios.get( variable.urlApi+'/pqrs/getlineasnegocio',  {
            'Content-Type': 'application/x-www-form-urlencoded',
            'responseType': 'json'
        }).then(
            (result) => {
                var centroOperaciontras = result;
                var { centroOperaciones } = this.state;
                centroOperaciones = [];
                centroOperaciontras.data.forEach((centroOperacions) => {
                    centroOperaciones.push(centroOperacions);
                })
                this.setState({ centroOperaciones })
                console.log(this.state);
            }
        );

    }

    componentDidMount() {
        this.setState({
            caragandoInical: true
        })
        localStorage.clear()
        //---------------------------------- Get Ciudades-------------------------//
        axios.get(variable.urlApi+'/ciudad',  {
            'Origin': 'https://apimrbono.digitalgex.com.co/',
            'Content-Type': 'application/x-www-form-urlencoded',
            'responseType': 'json'
        }).then(
            (result) => {
                var ciudadestras = result.data;
                const { ciudades } = this.state;
                ciudadestras.data.forEach((ciudad) => {
                    ciudades.push(ciudad);
                })
                this.setState({ ciudades })
                this.setState({
                    caragandoInical: false
                })

            }
        ).catch(()=>{
            const { history } = this.props
            history.push("/")
        }
        );
    }
    handleChange = (event) => {
        this.state.setAge(event.target.value);
    };
    render() {

        return (
            this.state.caragandoInical === true ?
                <div className="item-center   caragndo-spin" >
                    <br />
                    <br />
                    {/* <img src={loading} width="60" alt="Cargando..." /> */}
                    <img src={loadingPuntos} width="150" height="150" style={{marginTop:"-3.3em"}} alt="Cargando..." />
                    {/* <CircularProgress color="inherit" style={{ color: "#0A8342" }} /> */}
                </div>
                :

                <Suspense fallback={<div>Loading...</div>}>
                    <Fragment>
                        <div className="App-header">
                            <div className="capanegra">
                                
                                {/* <br />
                                <br />
                                <br />
                                <br /> */}
                               
                                <h1 className="bienvenido">Bienvenido </h1>
                                
                                <small style={{marginRight: "1em", marginLeft:"1em", marginBottom:"15px"}}>
                                    Para nosotros es importante la experiencia de compra de nuestros clientes.
                                   
                                </small>
                                <small> Favor diligencie la siguiente información </small>
                                <br />
                                <br />
                                <small>
                                    Seleccione Ciudad
                        </small>
                                <FormControl className="from-selec">
                                    <Select
                                        defaultValue=""
                                        // onChange={() => this.siguiente()}
                                        input={<BootstrapInput id="ciudad" />}
                                    >
                                        {
                                            this.state.ciudades.map(
                                                (ciudad) => {
                                                    return (
                                                        <MenuItem onClick={() => this.selecitemCiudad(ciudad.id)} key={ciudad.id} value={ciudad.id}>
                                                            {ciudad.nombre  }
                                                        </MenuItem>
                                                    );
                                                }
                                            )
                                        }
                                    </Select>
                                </FormControl>
                                <br />
                                <small>
                                    Seleccione Linea de negocio
                                </small>


                                <FormControl className="from-selec">
                                    <Select
                                        defaultValue=""
                                        input={<BootstrapInput />}
                                    >
                                        {
                                            this.state.centroOperaciones.length === 0 ?
                                                [1].map(
                                                    () => {
                                                        return (<MenuItem>
                                                            Selecione Ciudad
                                                        </MenuItem>)
                                                    }
                                                ) :
                                                this.state.centroOperaciones.map(
                                                    (centrOP) => {
                                                        return (
                                                            <MenuItem onClick={() => this.siguiente(centrOP)} key={centrOP.id} value={centrOP.id}>
                                                                {centrOP.nombre.toUpperCase()}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )
                                        }
                                    </Select>
                                </FormControl>
                                <br />
                                {/* <Button onClick={() => this.siguiente()} variant="contained" color="primary" className="siguinte" >Siguiente</Button> */}
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <a href="https://digitalgex.com.co/">
                                    <img src={imgDigital} width="75" alt="Producer BY DigitalGex" />
                                </a>
                            </div>
                        </div>
                    </Fragment>
                </Suspense>
        )
    }





}

export default withRouter(Identificacion);


