import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { Component } from 'react'
import Logo from '../../assets/Logo_01.png'

export default class ModalValidacion extends Component {
    constructor(props) {
        super(props);
        console.log(props);
        // this.state({

        // })
    }


    handleClose = () => {
        const { onClose, selectedValue } = this.props;

        onClose(selectedValue);
    };
    render() {
        const { open } = this.props;
        return (
            <Dialog onClose={this.handleClose} open={open}>
                <DialogTitle className="item-center" >   {this.props.title || ""} </DialogTitle>
                <small className="item-center">
                    <img src={Logo} alt="" width="130"></img>
                </small>
                <br />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <center>
                            <p style={{color: this.props.color || 'gray',}}>
                                {this.props.mensaje || "Por favor diligencie la calificación de todas las preguntas seleccionado el emoticon que mejor defina su experiencia"}
                            </p>
                        </center>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );

    }
}
