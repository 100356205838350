// import logo from '../assets/logo.svg';

import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Identificacion from './pages/Identificacion.jsx';
import Encuesta from './pages/Encuesta1.jsx'
import Recomendacio from './pages/Recomendacion.jsx'
import DatosPersonales from './pages/DatosPersonales.jsx'
import Agradecimientos from './pages/Agradecimientos.jsx'

import './App.css';

function App() {
  return (
    <div className="App">
      <div> 
        <BrowserRouter>
          <Suspense fallback={<h1>Loading...</h1>}>
            <Switch>
              <Route path='/' exact
                render={() => <Identificacion />} />
              <Route path='/encuesta/:idCentroOp' exact
                render={() => <Encuesta />} />
              <Route path='/recomendacion' exact
                render={() => <Recomendacio />} />
              <Route path='/datos' exact
                render={() => <DatosPersonales />} />
              <Route path='/finalencuesta' exact
                render={() => <Agradecimientos />} />
              {/* <Route path="/404" component={NotFoundPage} /> */}
              <Redirect to="/" />


              {/*<Route path='/post/:id' /> */}
            </Switch>
          </Suspense> 
        </BrowserRouter>
      </div>


    </div>
  );
}

export default App;
