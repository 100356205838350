import React, { Component, Fragment } from 'react'
import Logo from '../../assets/Logo_01.png'
import  loadingPuntos  from "../../assets/loadingPuntos.svg";


export default class Agradecimientos extends Component {

    componentDidMount() {
        setTimeout(() => {
            window.location.href='/'
         }, 3000) 
    }
    render() {
        return (
            <Fragment >
                <small className="item-center-t caragndo-spin">
                    <img src={Logo} alt="" width="200"></img>
                    <h1 className="color-text-blanco-gracias"> ¡Gracias! </h1>
                    <small className="texto-agradeciiento">
                        Hemos recibido con éxito tu califcación.
                    </small>
                    <img src={loadingPuntos} width="150" height="150" style={{marginTop:"-3.3em"}} alt="Cargando..." />
                </small>
            </Fragment>
        )
    }
}
