import React from 'react'
import imagenLogo from '../../assets/Logo_3.png'
import unapausa from '../../assets/una-pausa-png-a1.png'

export default function Heder() {
    return (
        <div className="heder-container">
            <img src={imagenLogo} width="130" alt="MrBono"/>
            <img src={unapausa} width="100" alt="MrBono"  />
        </div>
    )
}
