import SentimentVeryDissatisfiedSharpIcon from '@material-ui/icons/SentimentVeryDissatisfiedSharp';
import SentimentDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentDissatisfiedOutlined';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@material-ui/icons/SentimentVerySatisfiedOutlined';
import TexAreaP from './texArea'
import React, { Component } from 'react'
import { IconButton, } from '@material-ui/core';


export default class pregunataTipo1 extends Component {


    constructor(props) {
        super(props);
        this.state = {
            value: "",
            respuesta: "",
        }
    }

    render() {
        const { obtnerValue, obtenerNota } = this.props
        return (
            <div>
                <div className="col item-center">
                    <div className="row pregunta-row">
                        <strong>
                            {this.props.pregunta || "Amabilidad"}
                        </strong>
                    </div>
                    <div className="row">
                        <small>
                            <small onClick={() => obtnerValue(4, this.props.numeroPregunta)}>
                                <IconButton onClick={() => this.calificacionOptrnida(4, this.props.numeroPregunta)} color="inherit" aria-label="" component="span">
                                    <SentimentVerySatisfiedOutlinedIcon id={"btn-4" + this.props.numeroPregunta} className="icon-pregunta" />
                                </IconButton>
                            </small>
                            <small onClick={() => obtnerValue(3, this.props.numeroPregunta)}>
                                <IconButton onClick={() => this.calificacionOptrnida(3, this.props.numeroPregunta)} color="inherit" aria-label="" component="span">
                                    <SentimentSatisfiedOutlinedIcon id={"btn-3" + this.props.numeroPregunta} className="icon-pregunta" />
                                </IconButton>
                            </small>
                            <small onClick={() => obtnerValue(2, this.props.numeroPregunta)}>
                                <IconButton onClick={() => this.calificacionOptrnida(2, this.props.numeroPregunta)} color="inherit" aria-label="" component="span">
                                    <SentimentDissatisfiedOutlinedIcon id={"btn-2" + this.props.numeroPregunta} className="icon-pregunta" />
                                </IconButton>
                            </small>
                            <small onClick={() => obtnerValue(1, this.props.numeroPregunta)}>
                                <IconButton onClick={() => { this.calificacionOptrnida(1, this.props.numeroPregunta) }} color="inherit" aria-label="" component="span">
                                    <SentimentVeryDissatisfiedSharpIcon id={"btn-1" + this.props.numeroPregunta} className="icon-pregunta" />
                                </IconButton>
                            </small>
                        </small>
                    </div>
                    <div className="col">
                        <TexAreaP label={this.props.nota || "Null"} caracteres={200} optenervalue={(e) => {

                            this.setState({
                                respuesta: e.target.value
                            })
                            return obtenerNota(e.target.value, this.props.numeroPregunta)

                        }} />
                    </div>
                    <br />
                </div>



            </div>
        )
    }
    calificacionOptrnida(numerCalif, numeroPregunta) {
        // const {obtnerValue}= this.props
        // console.log("Calificacion es: " + numerCalif);
        for (let i = 1; i < 5; i++) {
            var element;
            if (numerCalif !== i) {
                element = document.getElementById("btn-" + i + numeroPregunta);
                element.style.backgroundColor = "#ffffff"
                element.style.color = "#0A8342"
                element.style.borderRadius = "40px"
            }
        }
        switch (numerCalif) {
            case 1:
                element = document.getElementById("btn-" + numerCalif + numeroPregunta);
                element.style.backgroundColor = "#ff0000"
                element.style.color = "#ffffff"
                element.style.borderRadius = "40px"
                this.setState({ value: 1 });



                break;
            case 2:
                element = document.getElementById("btn-" + numerCalif + numeroPregunta);
                element.style.backgroundColor = "#f7e433"
                element.style.color = "#ffffff"
                element.style.borderRadius = "40px"
                this.setState({ value: 2 });



                break;

            case 3:
                element = document.getElementById("btn-" + numerCalif + numeroPregunta);
                element.style.backgroundColor = "#62b2a9"
                element.style.color = "#ffffff"
                // element.style.width = "90"
                element.style.borderRadius = "40px"
                this.setState({ value: 3 });


                break;

            case 4:
                element = document.getElementById("btn-" + numerCalif + numeroPregunta);
                element.style.backgroundColor = "#3bae3e"
                element.style.color = "#ffffff"
                element.style.borderRadius = "40px"
                this.setState({ value: 4 });
                break;
            default:
                break;
        }
    }

}



