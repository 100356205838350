import { fade, makeStyles, TextField } from '@material-ui/core';
import React from 'react'
const useStylesReddit = makeStyles((theme) => ({
    root: {
        width: '20em',
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        fontSize: 15,
        borderRadius: '0.75em',
        backgroundColor: '#e5e4df',
        margin: '60px 0px 0px 25px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
            backgroundColor: '#e5e4df',
        },
        '&$focused': {
            //backgroundColor: '#d8d2b4',
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: '#e5e4df',
        },
    },
    focused: {
  
    },
}));

function RedditTextField(props) {
    const classes = useStylesReddit();

    return <TextField  inputProps={{maxLength: props.maxLength,}}   InputProps={{ classes, disableUnderline: true, fullWidth:true}} {...props} />;
}
export default function TexArea(props) {
    return (
        <div style={{paddingRight:'20px'}} className="col">
            <RedditTextField

                onChange={props.optenervalue}
                // onKeyPress= {props.optenervalue}
                multiline
                margin="normal"
                rows={5}
                maxLength={props.caracteres}
                label={props.label}
                //className={classes.margin}
                defaultValue=""
                variant="filled"
                id="reddit-input"
            />
            
            <small style={{fontSize:"5", color:"green"}}>
                *Máximo {props.caracteres} caracteres
            </small>
        </div>
    )
}
