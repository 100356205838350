import { fade, makeStyles, TextField } from '@material-ui/core';
import React from 'react'

export const useStylesReddit = makeStyles((theme) => ({
    root: {
        width: '20em',
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        fontSize: 12,
        borderRadius: '0.75em',
        backgroundColor: '#e5e4df',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
            backgroundColor: '#e5e4df',
        },
        '&$focused': {
            backgroundColor: '#e5e4df',
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
    focused: {},
}));

function RedditTextField(props) {
    const classes = useStylesReddit();
    return <TextField inputProps={{maxLength: props.maxLength}} InputProps={{ classes, disableUnderline: true }} {...props} />;
}
export default function imput(props) {
    return (
        <div>
            <RedditTextField
                onChange={props.optenervalue}
                label={props.label}
                maxLength={props.maxLength}
                //className={classes.margin}
                defaultValue=""
                variant="filled"
                id="reddit-input"
                tipo={props.type}
            />
        </div>
    )
}
