


import React, { Component } from 'react'
import TexArea from '../components/texArea'
import Heder from '../components/heder'
import Imput from '../components/imput'
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, InputBase, InputLabel, MenuItem, Select, Snackbar, withStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { withRouter } from 'react-router';
import axios from 'axios';
import loading from "../../assets/loading.gif";
import  loadingPuntos  from "../../assets/loadingPuntos.svg";
import ValidacionCamposModal from '../components/modalValidacion';
import variable from '../variables' 

const GreenCheckbox = withStyles({
    root: {
      color: "#0A8342",
      '&$checked': {
        color: "#0A8342",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

 const BootstrapInputTD = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        width: '13em',
        height: 20,
        borderRadius: '0.75em',
        position: 'relative',
        backgroundColor: '#e5e4df',
        fontSize: 15,
        padding: '15px 26px 15px 12px',
        margin: '15px 0px 15px 0px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: '0.75em',
            backgroundColor: '#e5e4df',
            borderColor: '#CAB453',
        },
    },
}))(InputBase);



class DatosPersonales extends Component {
    constructor(props) {
        super();
        this.state = {
            nombreCompleto: "",
            celular: "",
            email: "",
            comentarios: "",
            idEncuesta: "",
            cargando: false,
            falloCarga: false,
            opendataI: false,
            checked: false,
            tiposId: [
                'CC',
                'NIT'
            ],
            tiposSolicitud:[],
            tipoId:"",
            tipoSolicitud: '',
            identification:"",
            selectedFile: null,
            nameFileUpload: "",
            mesajeError: "",
            WithSurvey: localStorage.getItem(variable.LSCONSTNAME.ISWITHSURVEY) === "true" ? true : false 
        };
        this.WithSurvey = localStorage.getItem(variable.LSCONSTNAME.ISWITHSURVEY)
        this.lineanegocio = localStorage.getItem(variable.LSCONSTNAME.LINEANEGOCIO)
        this.ciudad = localStorage.getItem(variable.LSCONSTNAME.CIUDAD)
        this.prqId = localStorage.getItem(variable.LSCONSTNAME.PQRID) 

    }
    componentDidMount() {
        const { history } = this.props
        if (this.WithSurvey === "" || this.WithSurvey === null || this.lineanegocio === "" 
            || this.lineanegocio === null || this.ciudad=== "" || this.ciudad === null){
           history.push("/")
        } else if((this.prqId === "" || this.prqId !== null) && this.prqId.length > 5){
            history.push("/encuesta/"+ this.lineanegocio) 
        }
        axios.get( variable.urlApi+'/pqrs/selectTipos', {
            'Content-Type': 'application/json',
        }).then((repont)=>{
            console.log(repont);
            this.setState({
                tiposId: repont.data.tiposIdentification,
                tiposSolicitud: repont.data.tiposSolicitud
            })
        }).catch(()=>{})
    }

    acpetPolitica (event)  {
        //setChecked(event.target.checked);
        this.setState({
            checked: event.target.checked
        });
    }

    onFileChange  (event)  { 
        // Update the state
        if(event.target.files[0]){
            this.setState({ nameFileUpload: event.target.files[0].name }); 
            this.setState({ selectedFile: event.target.files[0] }); 
        } else{
            this.limpiarUpload() 
        }
    }

    limpiarUpload(){
        this.setState({ nameFileUpload: "" }); 
        this.setState({ selectedFile: null }); 
    }

    render() {
        return (
            this.state.falloCarga ?
                <div className="caragndo-spin">
                    <img src={loading} width="60" alt="Cargando..." />
                    <img src={loadingPuntos} width="150" height="150" style={{marginTop:"-3.3em"}} alt="Cargando..." />
                    <Snackbar message="Token caducado" open={true} autoHideDuration={1000} onClose={()=>{
                         const { history } = this.props
                         history.push("/");
                    }}
                    />
                </div>
                :
                <div className="contenedor-modal-cargando">
                    <Heder />
                    <br />
                    <p className="p-20">Puedes contarnos lo sucedido y dejarnos los datos si quieres que te contactemos:
             </p>
                    <br />
                    <div className="col item-center">
                    <FormControl className="from-selec" variant="filled">
                            <InputLabel htmlFor="filled-age-native-simple">Tipo Solicitud </InputLabel>
                            <Select
                                //defaultValue=""
                                // onChange={() => this.siguiente()}
                                input={<BootstrapInputTD id="tipoID" />}
                            >
                                {
                                    this.state.tiposSolicitud.map(
                                        (tiposSolicitudOne) => {
                                            return (
                                                <MenuItem onClick={() => this.setState({tipoSolicitud: tiposSolicitudOne.id})} key={tiposSolicitudOne.id} value={tiposSolicitudOne.id}>
                                                    {tiposSolicitudOne.nombre}
                                                </MenuItem>
                                            );
                                        }
                                    )
                                }
                            </Select>
                        </FormControl>

                        <Imput label="Nombre Completo" optenervalue={(e) => {
                            this.setState({
                                nombreCompleto: e.target.value
                            });
                        }} />
                        <br />
                        <Imput label="Celular" maxLength="10" optenervalue={(e) => {
                            this.setState({
                                celular: e.target.value
                            });
                        }} type="number" />
                        <br />
                        <Imput label="Email" optenervalue={(e) => {
                            this.setState({
                                email: e.target.value
                            });
                        }} />
                        <br />
                        <FormControl className="from-selec" variant="filled">
                            <InputLabel htmlFor="filled-age-native-simple">Tipo Identificación</InputLabel>
                            <Select
                                //defaultValue=""
                                // onChange={() => this.siguiente()}
                                input={<BootstrapInputTD id="tipoID" />}
                            >
                                {
                                    this.state.tiposId.map(
                                        (tipoid) => {
                                            return (
                                                <MenuItem onClick={() => this.setState({tipoId: tipoid.id})} key={tipoid.id} value={tipoid.nombre}>
                                                    {tipoid.nombre}
                                                </MenuItem>
                                            );
                                        }
                                    )
                                }
                            </Select>
                        </FormControl>
                        <br />
                        <Imput label="Documento ID" maxLength="10" optenervalue={(e) => {
                            this.setState({
                                identification: e.target.value
                            });
                        }} />
                        <br />
                        <div className="row">
                            <TexArea label="Comentarios de lo acontecido" caracteres={2000} optenervalue={(e) => {
                                this.setState({
                                    comentarios: e.target.value
                                });
                            }} />
                        </div>
                        <br/>
                        <p>
                            {this.state.nameFileUpload}
                            {this.state.nameFileUpload !== '' ?
                                <IconButton aria-label="delete" onClick={() => this.limpiarUpload()}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                                : null
                            }
                        </p>
                        <input
                            accept="image/*"
                            className="imput-File-hiden"
                            id="contained-button-file"
                            //multiple
                            onChange={(e)=>this.onFileChange(e)}
                            type="file"
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" color="inherit" component="span" >
                                Subir Adjunto
                            </Button>
                        </label>
                        <br />
                        <FormGroup row>
                            <p className='terminos-conditions'>
                                <FormControlLabel control={
                                    <GreenCheckbox
                                        //checked={this.setState({ checked })}
                                        onChange={(e)=>this.acpetPolitica(e)}
                                        inputProps={{ 'aria-label': 'primary  checkbox' }}
                                        color='primary'
                                    />  
                                }
                                label=""/>
                                He leido y acepto políticas de tratamientos de datos personales de <a href="https://www.mrbono.co/politica-de-tratamiento-de-datos-personales" target='_black'>Inversisa</a>
                            </p>
                        </FormGroup>
                        <Button onClick={() => this.enviar()} variant="contained" color="primary" className="siguinte" >
                            {!this.state.WithSurvey ?
                                'Envar'
                                :
                                `Paso 1 de 3`
                            } 
                            {this.state.WithSurvey}
                        </Button>
                        <br />
                        <br />
                        {
                            this.state.cargando ?
                                <small className="modal-cargando">
                                    <br />
                                    <br />
                                    <br />
                                    <img src={loading} width="60" alt="Cargando..." />
                            <img src={loadingPuntos} width="150" height="150" style={{ marginTop: "-2.5em" }} alt="Cargando..." />

                                </small>
                                : ""
                        }
                    </div>
                    <ValidacionCamposModal open={this.state.opendataI} mensaje={this.state.mesajeError} title="!Ups"  color="red" onClose={()=>{
                        this.setState({
                            opendataI: false
                        })
                    }} />
                </div>             
        )
    }
    redirect(){
        const { history } = this.props
        if (!this.state.WithSurvey){
            history.push("/finalencuesta")
        }else{
            history.push("/encuesta/"+ this.lineanegocio) 
        }
    }
    enviar() {
       
        if (!(this.state.celular === "" || 
        this.state.nombreCompleto === "" || 
        this.state.email === "" || 
        this.state.comentarios === ""|| 
        this.state.tipoId==="" ||
        this.state.checked=== false||
        this.state.identification=== "" ||
        !this.isEmail(this.state.email ) ||
        //this.state.selectedFile || 
        //Number.isInteger(this.state.celular) ||
        !this.state.celular.length > 9 
        //Number.isInteger(this.state.identification)
        )) {
            let body = {
                //"repuestas": [],
                "contacto": {
                    "nombreCompleto": this.state.nombreCompleto,
                    "celular": this.state.celular,
                    "email": this.state.email,
                    "comentarios": this.state.comentarios,
                    "identificacion": this.state.identification,
                    "TipoId": this.state.tipoId,
                },
                //"idCentroOp": localStorage.getItem(variable.LSCONSTNAME.LINEANEGOCIO),
                "lineaNegocio":  this.lineanegocio,
                "idCiudad":  this.ciudad,
                "calificacion": 4,
                "tipoSolicitud": this.state.tipoSolicitud,
              }
            this.setState({
                cargando: true
            })
            axios.post( variable.urlApi+'/pqrs', body, {
                'Content-Type': 'application/json',
            }).then(
                (result) => {
                    //result["data"]["code"]===200
                    localStorage.setItem(variable.LSCONSTNAME.PQRID, result["data"].pqr.id)
                    console.log(result["data"].pqr.id);
                    if (result["data"]["code"] === 200) {
                        let dataBody = new FormData()
                        if (this.state.selectedFile){
                            dataBody.append("file",this.state.selectedFile, 'File')
                            dataBody.append("prqId", result["data"].pqr.id,)
                            axios.post(variable.urlApi+'/files/pqr', dataBody, {
                                'Content-Type': 'multipart/form-data'
                            }).then((r)=>{
                                this.setState({
                                    cargando: false
                                })
                                this.redirect()
                            })
                        }else {
                            this.setState({
                                cargando: false
                            })
                            this.redirect()
                        }

                    } else {
                        this.setState({
                            cargando: false
                        })
                        const { history } = this.props
                        history.push("/")
                    }

                }).catch(()=>{
                    const { history } = this.props
                    history.push("/")
                }
                );




        } else {
            let errMensage= ""
            // const { history } = this.props;
            if (this.state.nombreCompleto === "" )    errMensage += `\n* Nombre completo vacio o invalido` ; 
            if (this.state.celular === "" || Number.isInteger(this.state.celular) ||  !(this.state.celular.length > 9) )  errMensage += `\n* Numero de celular Invalido` ; 
            if (this.state.email === ""  )    errMensage += `\n* email vacio` ; 
            if (!this.isEmail(this.state.email) )    errMensage += `\n* email invalido ` ; 
            if (this.state.tipoId === "")    errMensage += `\n* Tipo de identificacion vacio` ; 
            if (this.state.identification === "" )    errMensage += `\n* identificacion vacio o invalido` ; 
            if (this.state.comentarios === "")    errMensage += `\n* Comentarios vacios o valores invalidos` ; 
           // if (this.state.selectedFile === "")    errMensage += `\n*  vacios o valores invalidos` ; 
            if (!this.state.checked )    errMensage += `\n* Aceptar  política de privacidad ` ; 
            
            
            
            this.setState({mesajeError: 'Por favor diligencie sus datos de contacto  y  comentarios'+ errMensage })
            this.setState({
                opendataI: true
            })
        }

    }
    isEmail(email= ""){
       return new RegExp("[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}").test(email )
    }
}

export default withRouter(DatosPersonales);