import React, { Component } from 'react'
import SentimentVerySatisfiedOutlinedIcon from '@material-ui/icons/SentimentVerySatisfiedOutlined';
import TexArea from '../components/texArea'
import Heder from '../components/heder'
import { Button, IconButton, } from '@material-ui/core';
import { withRouter } from 'react-router';
import ValidacionCamposModal from '../components/modalValidacion';
import ModalDatosDeContacto from '../components/modaDatosDeContacto';
import axios from 'axios';
import variable from '../variables' 
// import explocion from '../../assets/explocion.svg'
import loading from "../../assets/loading.gif";
import loadingPuntos from "../../assets/loadingPuntos.svg";


class Recomendacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando: false,
            datosPersonales: false,
            open: false,
            calificionVariable: "",
            porqueReco: "",
            idEncuesta: "",
            isGracias: false,
            falloCarga: false,
        };

        if (
            localStorage.getItem("idCentroOp") === "" || localStorage.getItem("idCentroOp") === null ||
            localStorage.getItem("calificacion") === "" || localStorage.getItem("calificacion") === null ||
            localStorage.getItem("nota1") === null || localStorage.getItem("nota2") === null || localStorage.getItem("nota3") === null || localStorage.getItem("nota4") === null ||
            localStorage.getItem("numeroPreguna1") === null || localStorage.getItem("numeroPreguna1") === "" ||
            localStorage.getItem("numeroPreguna2") === null || localStorage.getItem("numeroPreguna2") === "" ||
            localStorage.getItem("numeroPreguna3") === null || localStorage.getItem("numeroPreguna3") === "" ||
            localStorage.getItem("numeroPreguna4") === null || localStorage.getItem("numeroPreguna4") === "" ||
            localStorage.getItem("calificacion1") === null || localStorage.getItem("calificacion2") === null || localStorage.getItem("calificacion3") === null || localStorage.getItem("calificacion4") === null

        ) {
            const { history } = props
            this.state={
                falloCarga: false,
            }
            history.push("/");
        }
        this.pqrid = localStorage.getItem(variable.LSCONSTNAME.PQRID)
        this.preguntaf = localStorage.getItem(variable.LSCONSTNAME.PREGUNTAF)
    }
    render() {




        return (
            // {}
            <div className="contenedor-modal-cargando">
                <div className="body-cragando">
         
                    <Heder />
                    <br />
                    <p style={{margin:"1em"}}>¿Recomendarías MR BONO a tus familiares y amigos?</p>
                    {this.state.isGracias ?
                        <div className="modal-gracias ">
                            {/* <img src={explocion} width=""  alt="gracias" /> */}
                        </div> : ""
                    }
                    <br />
                    <div className="col item-center">
                        <div className="col">
                            <div className="row numeros-circule" >

                                <small id="btn-11" onClick={() => this.califcacion(11)} >
                                    <IconButton color="inherit" aria-label="" component="span">
                                        <SentimentVerySatisfiedOutlinedIcon className={this.state.isGracias?"App-logo": ""} style={{ fontSize: 45 }} />
                                    </IconButton>
                                </small>
                                <small id="btn-10" onClick={() => this.califcacion(10)}>
                                    <IconButton color="inherit" aria-label="" component="span">
                                        10
                            </IconButton>
                                </small>
                                <small id="btn-9" onClick={() => this.califcacion(9)} >
                                    <IconButton color="inherit" aria-label="" component="span">
                                        9
                            </IconButton>
                                </small>
                                <small id="btn-8" onClick={() => this.califcacion(8)}>
                                    <IconButton color="inherit" aria-label="" component="span">
                                        8
                            </IconButton>
                                </small>
                                <small id="btn-7" onClick={() => this.califcacion(7)}>
                                    <IconButton color="inherit" aria-label="" component="span">
                                        7
                            </IconButton>
                                </small>
                                <small id="btn-6" onClick={() => this.califcacion(6)}>
                                    <IconButton color="inherit" aria-label="" component="span">
                                        6
                            </IconButton>
                                </small>

                            </div>
                            <br />
                            <div className="row numeros-circule">
                                <small id="btn-5" onClick={() => this.califcacion(5)} >
                                    <IconButton color="inherit" aria-label="" component="span">
                                        5
                            </IconButton>
                                </small>
                                <small id="btn-4" onClick={() => this.califcacion(4)}>
                                    <IconButton color="inherit" aria-label="" component="span">
                                        4
                            </IconButton>
                                </small>
                                <small id="btn-3" onClick={() => this.califcacion(3)}>
                                    <IconButton color="inherit" aria-label="" component="span">
                                        3
                            </IconButton>
                                </small>
                                <small id="btn-2" onClick={() => this.califcacion(2)}>
                                    <IconButton color="inherit" aria-label="" component="span">
                                        2
                            </IconButton>
                                </small>
                                <small id="btn-1" onClick={() => this.califcacion(1)}>
                                    <IconButton color="inherit" aria-label="" component="span">
                                        1
                            </IconButton>
                                </small>
                                <small id="btn-0" onClick={() => this.califcacion(0)} >
                                    <IconButton color="inherit" aria-label="" component="span">
                                        0
                            </IconButton>
                                </small>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                            <TexArea optenervalue={this.optenervalue}  caracteres={200} label="¿Por qué?" />
                        </div>
                    </div>
                    <br />
                    <br />

                    <br />
                    <Button onClick={() => this.siguiente()} variant="contained" color="primary" className="siguinte" >Paso 3 de 3</Button>
                    <br />
                    <br />
                    <ValidacionCamposModal open={this.state.open} mensaje="Por favor diligencie la calificación que mejor defina su experiencia y su comentarios " title="" onClose={this.handleClose} />
                    <ModalDatosDeContacto open={this.state.datosPersonales} funtionOK={this.funtionOK} funcionCancel={this.funcionCancel} onClose={this.handleClose} />
                </div>
                {
                    this.state.cargando===true ?
                        <small className="modal-cargando">
                            <br />
                            <br />
                            <img src={loading} width="60" alt="Cargando..." />
                            <img src={loadingPuntos} width="150" height="150" style={{ marginTop: "-2.7em" }} alt="Cargando..." />
                            {/* <div className="lds-dual-ring"></div> */}
                        </small>
                        : ""
                }
            </div>
        )
    }

    funtionOK = () => {
        // var data = new Date();
        localStorage.setItem("" + new Date().getDay() + new Date().getHours() + new Date().getDay(), this.state.idEncuesta);
        const { history } = this.props
        // console.log('preciono ok');
        history.push("/datos")

    }
    funcionCancel = () => {
        const { history } = this.props
        // console.log('preciono cancel');
        this.setState({
            datosPersonales: false
        })



        history.push("/finalencuesta")

    }
    siguiente() {




        if (this.state.calificionVariable !== "") {
            this.setState({
                cargando: true
            });

            if (
                localStorage.getItem("idCentroOp") === "" || localStorage.getItem("idCentroOp") === null ||
                localStorage.getItem("calificacion") === "" || localStorage.getItem("calificacion") === null ||
                localStorage.getItem("nota1") === null || localStorage.getItem("nota2") === null || localStorage.getItem("nota3") === null || localStorage.getItem("nota4") === null ||
                localStorage.getItem("numeroPreguna1") === null || localStorage.getItem("numeroPreguna1") === "" ||
                localStorage.getItem("numeroPreguna2") === null || localStorage.getItem("numeroPreguna2") === "" ||
                localStorage.getItem("numeroPreguna3") === null || localStorage.getItem("numeroPreguna3") === "" ||
                localStorage.getItem("numeroPreguna4") === null || localStorage.getItem("numeroPreguna4") === "" ||
                localStorage.getItem("calificacion1") === null || localStorage.getItem("calificacion2") === null || localStorage.getItem("calificacion3") === null || localStorage.getItem("calificacion4") === null

            ) {
                const { history } = this.props
                alert("Datos manipulados, de manera inescrupulosa tendrá que iniciar nuevamente la encuesta")
                history.push("/")


            }
            else {
                console.log(this.state.calificionVariable);
                let datosGuardar = {
                    repuestas: [],
                    idPqr: this.pqrid,
                    calificacion : parseInt(localStorage.getItem("calificacion"))
                }
                let respuestas = [1, 2, 3, 4];
                // let obRest={ "numeroPreguna": 1, "nota":"u",  "calificacion": 4 };

                // datosGuardar.idCentroOp = parseInt(localStorage.getItem("idCentroOp"));
                // datosGuardar.calificacion = parseInt(localStorage.getItem("calificacion"));
                //datosGuardar.idPqr()

                respuestas.forEach((res, index) => {
                    datosGuardar.repuestas.push({
                        idPregunta: (localStorage.getItem("numeroPreguna" + (index + 1))),
                        calificacion: parseInt(localStorage.getItem("calificacion" + (index + 1))),
                        nota: localStorage.getItem("nota" + (index + 1)),
                    });
                });

                datosGuardar.repuestas.push({
                    idPregunta: this.preguntaf,
                    calificacion: this.state.calificionVariable * 0.333,
                    nota: this.state.porqueReco,
                });
                console.log(JSON.stringify(datosGuardar))

                axios.post(variable.urlApi+'/pqrs/respuestas', datosGuardar, {
                    //'Origin': 'https://apimrbono.digitalgex.com.co/',
                    'Content-Type': 'application/json',
                    //'responseType': 'json'
                }).then(
                    (result) => {
                        if (result["data"]["code"] === 201) {
                            const { history } = this.props
                            history.push("/finalencuesta")
                        } else {
                            const { history } = this.props
                            history.push("/")
                        }
                        console.log(result["data"]);
                        this.setState({
                            idEncuesta: result["data"].idEncuesta
                        });
                    }).catch(()=>{
                        const { history } = this.props
                        history.push("/")
                    }
                    );
                localStorage.clear();


            }

        } else {

            this.setState({
                open: true
            })
        }

    }
    califcacion(numeroBoton) {
        for (let i = 0; i < 12; i++) {
            var element;
            if (numeroBoton !== i) {
                element = document.getElementById("btn-" + i);
                element.style.backgroundColor = "transparent"
                element.style.color = "#0A8342"
            }
        }
        element = document.getElementById("btn-" + numeroBoton);
        element.style.backgroundColor = "#0A8342"
        element.style.color = "#ffffff"

        var calificionVariable = numeroBoton;
        // numeroBoton = calificionVariable;
        this.setState({
            calificionVariable
        });
        if (numeroBoton === 11) {
            this.setState({
                isGracias: true
            })

            // setInterval(() => {
            //     this.setState({
            //         isGracias: false
            //     })
            // }, 10000);
        } else{
            this.setState({
                isGracias: false
            })
 
        }
        //console.log('Su calificacion',calificionVariable);

    }

    optenervalue = (e) => {
        // console.log(e.target.value);
        this.setState({
            porqueReco: e.target.value
        });

    }
    handleClose = (value) => {
        this.setState({
            open: false,
            datosPersonales: false
        })
    };

}


export default withRouter(Recomendacion)