import { Button, Divider } from '@material-ui/core';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Heder from '../components/heder'
import PreguntaTipo1 from '../components/pregunataTipo1'
import axios from 'axios';
import ModalValidacion from '../components/modalValidacion';
import  loadingPuntos  from "../../assets/loadingPuntos.svg";
import variable from '../variables' 




class Encuesta1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            cargando: false,
            preguntas: [],
            respuestas: [{
                numeroPreguna: 0,
                nota: "",
                calificacion: 0,
            }],
       
        }

    }
    componentDidMount() {
        this.setState({ cargando: true });
        const { history, match } = this.props
        this.WithSurvey = localStorage.getItem(variable.LSCONSTNAME.ISWITHSURVEY)
        this.lineanegocio = localStorage.getItem(variable.LSCONSTNAME.LINEANEGOCIO)
        this.ciudad = localStorage.getItem(variable.LSCONSTNAME.CIUDAD)
        this.prqId = localStorage.getItem(variable.LSCONSTNAME.PQRID) 
        if (this.WithSurvey === "" || this.WithSurvey === null || this.lineanegocio === "" 
            || this.lineanegocio === null || this.ciudad=== "" || this.ciudad === null
            || this.prqId=== "" || this.prqId === null || !(match.params.idCentroOp=== this.lineanegocio)){
           history.push("/")
        }
        ///-------- coneccion base de datos
        axios.get(variable.urlApi+'/pqrs/getpreguntas', {}, {
            'Origin': 'https://apimrbono.digitalgex.com.co/',
            'Content-Type': 'application/x-www-form-urlencoded',
            'responseType': 'json'
        }).then(
            (result) => {
                var preguntastras = result;
                var { preguntas } = this.state;
                var { respuestas } = this.state;
                respuestas = [];
                preguntastras.data.forEach((preguntasr) => {
                    var obj = {
                        numeroPreguna: preguntasr.id,
                        nota: "",
                        calificacion: 0,
                    }
                    respuestas.push(obj);
                    preguntas.push(preguntasr);
                })
                this.setState({ preguntas, respuestas })
                this.setState({ cargando: false });
            }
        ).catch(()=>{
            const { history } = this.props
            history.push("/")
        }
        );


    }

    render() {
        return (

            <div>
           
                <Heder />
              
                <p style={{margin:"1em"}}>
                    Califícanos y escribe tus
                    comentarios en las siguientes preguntas
                </p>


                {
                    this.state.cargando === true ?
                        <div className="item-center" >
                            <br />
                            <br />
                            <br />
                            <img src={loadingPuntos} width="150" height="150" style={{marginTop:"-3.3em"}} alt="Cargando..." />
                            {/* <CircularProgress color="inherit" style={{ color: "#0A8342", padding: "10em" }} /> */}
                        </div>
                        :
                        this.state.preguntas.map((pregunata, index) => {
                            if (index !== 4) {
                                return (
                                    <div key={pregunata.id}>
                                        <br />
                                        <Divider key={pregunata.id} variant="middle" />
                                        <br />
                                        <PreguntaTipo1
                                            obtenerNota={this.obtenerNota}
                                            obtnerValue={this.obtnerValue}
                                            numeroPregunta={pregunata.id}
                                            pregunta={pregunata.nota}
                                            nota={pregunata.pregunta} />
                                    </div>
                                );
                            } else {
                                localStorage.setItem(variable.LSCONSTNAME.PREGUNTAF, pregunata.id);
                                return (
                                    <div></div>
                                )
                            }
                        })
                }

                <br />
                <br />
                {
                    this.state.cargando === false ?

                        <Button onClick={() => this.siguiente()} variant="contained" color="primary" className="siguinte" >Paso 2 de 3</Button>
                        :
                        <br />
                }
                <br />
                <br />
                <div>
                    {/* <Typography variant="subtitle1">Selected: {selectedValue}</Typography> */}
                    <br />
                    {/* <Button variant="outlined" color="primary"
                     onClick={this.handleClickOpen}
                     >
                       Dialog
                    </Button> */}
                    <ModalValidacion open={this.state.open} onClose={this.handleClose} />
                </div>
                <br />


            </div>
        );
    }
    siguiente() {
        var calificacion = 0;
        var respuestas = [];
        var preguanFalta = false;
        const { history } = this.props
        this.state.respuestas.forEach((respt, index) => {
            if (respt.calificacion === 0 && index !== 4) {
                preguanFalta = true;
            }
            respuestas.push(respt)
            calificacion += respt.calificacion
        });

        if (preguanFalta) {
            this.setState({
                open: true
            })
        } else {
            const { match } = this.props;
            // match.params.idCentroOp
            ///-------- coneccion base de dato

            //openDataba
            //localStorage.clear();
            localStorage.setItem("idCentroOp", match.params.idCentroOp);
            localStorage.setItem(variable.LSCONSTNAME.CALIFICACION, calificacion);
            respuestas.forEach((res, index) => {
                localStorage.setItem("numeroPreguna" + (index + 1), res.numeroPreguna);
                localStorage.setItem("calificacion" + (index + 1), res.calificacion);
                localStorage.setItem("nota" + (index + 1), res.nota);
            });
            // localStorage.setItem(idCentroOp,S match.params.idCentroOp);

            // axios.post(variable.urlApi+'/encuesta/getPreguntas', {}, {
            //     'Origin': 'https://apimrbono.digitalgex.com.co/',
            //     'Content-Type': 'application/x-www-form-urlencoded',
            //     'responseType': 'json'
            // }).then(
            //     (result) => {

            //     }
            // );

            history.push("/recomendacion")
        }
    }
    obtnerValue = (value, numeroPreguna) => {
        var temp = [];
        var { respuestas } = this.state
        respuestas.forEach((resp) => {
            if (resp.numeroPreguna === numeroPreguna) {
                var objer = {
                    numeroPreguna: resp.numeroPreguna,
                    nota: resp.nota,
                    calificacion: value
                }
                temp.push(objer);

            } else {
                temp.push(resp);
            }
        });
        this.setState({
            respuestas: temp
        });
    }

    obtenerNota = (notas, numeroPreguna) => {
        // console.log(notas);
        var temp = [];
        var { respuestas } = this.state
        respuestas.forEach((resp) => {
            if (resp.numeroPreguna === numeroPreguna) {
                var objer = {
                    numeroPreguna: resp.numeroPreguna,
                    nota: notas,
                    calificacion: resp.calificacion
                }
                temp.push(objer);

            } else {
                temp.push(resp);
            }
        });
        this.setState({
            respuestas: temp
        });
    }

    // handleClickOpen = () => {
    //     this.setState({
    //         open: true
    //     })
    //   };

    handleClose = (value) => {
        this.setState({
            open: false
        })
    };
}



export default withRouter(Encuesta1)